import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import EmbedPlayer from '../../components/EmbedPlayer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <EmbedPlayer src="https://video.ibm.com/embed/23935269?volume=0" controls={['basic']} mdxType="EmbedPlayer" />
    <EmbedPlayer src="https://video.ibm.com/embed/recorded/24364591?volume=0" controls={['basic']} mdxType="EmbedPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      